<template>
  <CTabs variant="tabs" :active-tab="0">
    <CTab title="DISTRIBUCION POR PATIO" class="form-especial">
      <CCard bodyWrapper>
        <CRow>
          <CCol sm="10">
            <CRow>
              <CCol sm="6" class="mb-2">
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  addLabelClasses="required text-right"
                  :label="$t('label.yard')"
                  :horizontal="{
                    label: 'col-sm-12 col-lg-5',
                    input: 'col-sm-12 col-lg-7',
                  }"
                  :options="yards"
                  :disabled="isEmpty(yards)"
                  :value.sync="$v.estimatedInfo.YardId.$model"
                  :invalid-feedback="errorMessage($v.estimatedInfo.YardId)"
                  :is-valid="hasError($v.estimatedInfo.YardId)"
                >
                </CSelect>
              </CCol>
              <CCol sm="6" class="mb-2">
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  addLabelClasses="required text-right"
                  :label="'BL'"
                  :horizontal="{
                    label: 'col-sm-12 col-lg-5',
                    input: 'col-sm-12 col-lg-7',
                  }"
                  :options="blOptions"
                  :disabled="isEmpty(bls)"
                  :value.sync="$v.estimatedInfo.BillOfLadingYardId.$model"
                  :invalid-feedback="
                    errorMessage($v.estimatedInfo.BillOfLadingYardId)
                  "
                  :is-valid="hasError($v.estimatedInfo.BillOfLadingYardId)"
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CInput
                  addLabelClasses="text-right"
                  :horizontal="{
                    label: 'col-sm-12 col-lg-5',
                    input: 'col-sm-12 col-lg-7',
                  }"
                  :label="$t('label.actualInventory')"
                  size="sm"
                  v-model.trim="inventory"
                  disabled
                />
              </CCol>
              <CCol sm="6">
                <div class="form-group form-row">
                  <label
                    class="required text-right col-sm-12 col-lg-5 col-form-label-sm"
                    >{{ $t("label.weight") + " (TON)" }}</label
                  >
                  <div
                    class="input-group col-sm-12 col-lg-7 input-group-sm"
                  >
                    <money
                      addLabelClasses="required"
                      :label="$t('label.weight')"
                      v-model.trim="$v.estimatedInfo.Weight.$model"
                      :invalid-feedback="errorMessage($v.estimatedInfo.Weight)"
                      :is-valid="hasError($v.estimatedInfo.Weight)"
                      v-bind="measure"
                      class="form-control"
                      value="0"
                    >
                    </money>
                    <div
                      class="invalid-feedback show-feedback"
                      v-if="
                        !$v.estimatedInfo.Weight.verifyNumber &&
                        $v.estimatedInfo.BillOfLadingYardId.$anyDirty
                      "
                    >
                      {{ $t("validation.verifyNumber") }}
                    </div>
                    <div
                      class="invalid-feedback show-feedback"
                      v-if="
                        !$v.estimatedInfo.Weight.exceedtActualInventory &&
                        $v.estimatedInfo.BillOfLadingYardId.$anyDirty
                      "
                    >
                      {{ $t("validation.exceedtActualInventory") }}
                    </div>
                    <div
                      class="invalid-feedback show-feedback"
                      v-if="
                        !$v.estimatedInfo.Weight.notAvailableBl &&
                        $v.estimatedInfo.BillOfLadingYardId.$anyDirty
                      "
                    >
                      {{ $t("validation.notAvailableBl") }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="6" v-if="edit && modalEdit" class="mt-1 mb-2">
                <div>
                  <CSelect
                    :value.sync="estimatedInfo.Status"
                    :is-valid="statusSelectColor()"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-5',
                      input: 'col-sm-12 col-lg-7',
                    }"
                    size="sm"
                    :label="$t('label.status')"
                    :options="statusOptions"
                    addLabelClasses="text-right"
                  />
                </div>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="2" class="d-flex justify-content-end align-items-start">
            <CButtonGroup>
              <CButton
                color="add"
                size="sm"
                shape="square"
                :disabled="isInvalid"
                @click="confirmation"
              >
                <CIcon name="checkAlt" />
              </CButton>
              <CButton
                class="ml-1"
                color="wipe"
                size="sm"
                shape="square"
                v-c-tooltip="{
                  content: $t('label.clearFields'),
                  placement: 'top-end',
                }"
                @click="clearInputs"
              >
                <CIcon name="cil-brush-alt" />
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      </CCard>
    </CTab>
  </CTabs>
</template>
<script>
import { mapState } from "vuex";
import EstimatedValidations from "@/_validations/planificacion-estiba/EstimatedToShipValidations";
import { VMoney, Money } from "v-money";
import ModalMixin from "@/_mixins/modal";
import { formatMilDecimal } from "@/_validations/validacionEspeciales";
import { DateFormater } from "@/_helpers/funciones";

//DATA
function data() {
  return {
    estimatedInfo: {
      YardId: "",
      StowagePlanningBlDetailId: "",
      BillOfLadingYardId: "",
      UnitMeasureWeightBlDetId: "",
      Weight: 0,
      Status: 1,
    },
    bls: [],
    oldStatus: 1,
    inventory: "",
    currrentNroBl: "",
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
  };
}

// METHODS
function isEmpty(arr) {
  return arr.length == 0;
}
function clearInputs() {
  let arrKeys = Object.keys(this.estimatedInfo);
  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        this.estimatedInfo[arrKeys[i]] = 1;
        break;
      default:
        this.estimatedInfo[arrKeys[i]] = "";
    }
  }
  this.bls = [];
  this.inventory = "";
  this.currrentNroBl = "";
  this.oldStatus = 1;
  this.$v.$reset();
  this.$emit("clear");
}
function submit() {
  try {
    this.$v.estimatedInfo.$touch();

    if (this.$v.estimatedInfo.$invalid || this.$v.estimatedInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }

    if (!this.edit) {
      let newEstimated = {
        ...this.estimatedInfo,
        Weight: this.estimatedInfo.Weight,
        CurrentDate: DateFormater.formatOnlyDateWithSlash(new Date()),
        CurrentUser: this.user.Login,
        NroBl: this.currrentNroBl,
      };

      this.$emit("submit", newEstimated);
    } else {
      let editEstimated = {
        ...this.estimatedInfo,
        Weight: this.estimatedInfo.Weight,
        CurrentDate: DateFormater.formatOnlyDateWithSlash(new Date()),
        CurrentUser: this.user.Login,
        NroBl: this.currrentNroBl,
      };

      this.$emit("update", editEstimated);
    }
    this.clearInputs();
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function setForm(item) {
  if (!item) return;

  let arrKeys = Object.keys(this.estimatedInfo);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "Status":
        if (item[arrKeys[i]] != undefined) {
          this.estimatedInfo.Status = item[arrKeys[i]] ? 1 : 0;
          this.oldStatus = item[arrKeys[i]] ? 1 : 0;
        } else {
          this.estimatedInfo.Status = item.FgActBlDet ? 1 : 0;
          this.oldStatus = item.FgActBlDet ? 1 : 0;
        }
        break;
      case "Weight":
        this.estimatedInfo[arrKeys[i]] = item.Weight;
        break;
      default:
        this.estimatedInfo[arrKeys[i]] = item[arrKeys[i]]
          ? item[arrKeys[i]]
          : "";
    }
  }
  this.$v.$touch();
}
function confirmation() {
  if (!this.edit) {
    this.submit();
  } else {
    try {
      this.$v.estimatedInfo.$touch();
      if (this.$v.estimatedInfo.$error) {
        throw this.$t("label.errorsPleaseCheck");
      }
      this.statusConfirmation(
        this.oldStatus,
        this.estimatedInfo.Status,
        this.submit,
        ``
      );
    } catch (e) {
      this.notifyError({ text: e });
    }
  }
}
function statusSelectColor() {
  return this.estimatedInfo.Status === 1;
}
function formatNumericValue(val) {
  return parseFloat(val.replace(".", "").replace(",", ".")).toFixed(2);
}

//COMPUTED
function isInvalid() {
  if (
    this.edit &&
    this.estimatedInfo.BillOfLadingYardId == this.item.BillOfLadingYardId
  )
    return false;

  let found = this.current.find(
    (item) => item.BillOfLadingYardId == this.estimatedInfo.BillOfLadingYardId
  );

  if (found) return true;
  else return false;
}
function currentYard() {
  return this.estimatedInfo.YardId;
}
function blOptions() {
  return this.bls.map((bl) =>
    Object.assign({}, bl, {
      label: bl.NroBl,
      value: bl.BillOfLadingYardId,
    })
  );
}
function currentBl() {
  return this.estimatedInfo.BillOfLadingYardId;
}
function currentWeight(){
  return this.estimatedInfo.Weight;
}

export default {
  name: "estimated-inputs",
  mixins: [ModalMixin],
  components: {
    Money,
  },
  data,
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    modalEdit: {
      type: Boolean,
      default: false,
    },
    yards: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => null,
    },
    current: {
      type: Array,
      default: () => [],
    },
    declared: {
      type: String,
      default: '',
    },
  },
  validations() {
    return EstimatedValidations(this.inventory, this.declared);
  },
  directives: {},
  watch: {
    edit: function (val) {
      if (val) this.setForm(this.item);
    },
    modalOpen: function (val) {
      if (!val) this.clearInputs();
    },
    currentYard: function (newVal) {
      if (newVal) {
        let found = this.yards.find((item) => item.YardId == newVal);
        if (found) {
          this.bls = [...found.BlYardDetailJson];
        }
      } else {
        this.bls = [];
      }
    },
    currentBl: function (newVal) {
      if (newVal) {
        let found = this.bls.find((item) => item.BillOfLadingYardId == newVal);
        if (found) {
          this.inventory = formatMilDecimal(found.WeigthJson[1].Value);
          this.inventory = this.inventory ? this.inventory : "0";
          this.estimatedInfo.UnitMeasureWeightBlDetId =
            found.WeigthJson[1].UnitMeasureId;
          this.currrentNroBl = found.NroBl;
        }
      } else {
        this.inventory = "";
        this.currrentNroBl = "";
        this.estimatedInfo.UnitMeasureWeightBlDetId = "";
      }
    },
    currentWeight: function (newVal, oldVal) {
      this.$emit('value', newVal);
    },
  },
  methods: {
    isEmpty,
    clearInputs,
    submit,
    setForm,
    confirmation,
    statusSelectColor,
    formatNumericValue,
  },
  computed: {
    isInvalid,
    currentYard,
    blOptions,
    currentBl,
    currentWeight,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
<style lang="scss" scoped>
.show-feedback {
  display: block !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}
.mx-datepicker {
  width: 100%;
}
.vue-datepicker-drive {
  .mx-input {
    &:hover {
      border-color: #958bef;
    }
  }
  .mx-input {
    &:focus {
      color: #768192;
      background-color: #fff;
      outline: 0;
    }
  }
}
</style>