import { required, minLength, maxLength, minValue, maxValue, email, helpers } from "vuelidate/lib/validators";
import { onlyAlphanumericGuion, onlyAlphanumeric5, phoneTest } from '@/_validations/validacionEspeciales';
import { NumberFormater } from "@/_helpers/funciones";

const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
const emailToLower = (value) => email(value.toLowerCase());

export default (FgClassification, HeadingId, Activity, FgPackaging, CertifiedQuantity) => {
  let ApplyClassification = FgClassification ? { required } : {};
  let ApplyPackaging = FgPackaging ? { required } : {};
  return {
    BasicData: {
      Activity: { required },
      Bl: { required },
      Pol: { required },
      Pod: { required },
      Booking: { validationSeal: onlyAlphanumericGuion },
      Group: { required },
      Classification: ApplyClassification,
      Commodity: { required, NotRubro(){ return HeadingId ? true : false } },
      HeadingId: { required },
      Weight: { required(Value) {
        return NumberFormater.setNum(Value) > 0 ? true : false
      }},
      Volumen: {},
      Temperature: {},
      Viscosity: {},
      Quantity: { required(Value) {
        return NumberFormater.setNum(Value) > 0 ? true : false
      }},
    },
    Packaging: {
      PackagingId: ApplyPackaging,
      HeadingId: {},
      HeadingName: {},
      CommodityId: {},
      Serial: {},
      DescriptionOfGoods: {},
      Length: {},
      Width: {},
      Height: {},
      SquareMeters: {},
      Weight: { required(Value) {
        return NumberFormater.setNum(Value) > 0 ? true : false
      }}, 
      Quantity: { 
        minValue: minValue (1), 
        maxValue: maxValue (1000000), 
        CertifiedQuantity(Value) {
          if (CertifiedQuantity && CertifiedQuantity > 0) {
            return NumberFormater.setNum(Value) >= CertifiedQuantity ? true : false
          } else {
            return true
          }
        }
      },
      Volumen: {}
    },
    PackagingList: { required(Value) {
      return Value.some(item => item.Status != 0);
    }},
    Imdg: {
      ImdgClass: { required },
      ImdgId: { required },
      PackagingGroup: {},
      UnNumber: {},
      DescriptionGoods: { maxLength: maxLength(250), onlyAlphanumeric5 },
    },
    Seller: {
      SellerName: {},
      SellerAddress: {},
      SellerPrimaryPhone: { mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) },
      SellerSecondaryPhone: { mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) },
      SellerPrimaryEmail: { emailToLower, maxLength: maxLength(100) },
      SellerSecondaryEmail: { emailToLower, maxLength: maxLength(100) },
    },
    CustomBroker: {
      CustomBroker: {
        CustomBrokerId: {},
        CustomBrokerRif: {},
        CustomBrokerName: {},
        CustomBrokerAddress: {},
        CustomBrokerPrimaryPhone: {},
        CustomBrokerSecondaryPhone: {},
        CustomBrokerPrimaryEmail: {},
        CustomBrokerSecondaryEmail: {},
      },
      Consolidator: {
        ConsolidatorId: {},
        ConsolidatorRif: {},
        ConsolidatorName: {},
        ConsolidatorAddress: {},
        ConsolidatorPrimaryPhone: {},
        ConsolidatorSecondaryPhone: {},
        ConsolidatorPrimaryEmail: {},
        ConsolidatorSecondaryEmail: {},
      },
    },
    Consignee: {
      ClientTp: { required },
      RifClient: {},
      ConsigneeName: {},
      ConsigneePrimaryPhone: {},
      ConsigneePrimaryEmail: {},
      ConsigneeAddress: {},
      ConsigneeSecondaryPhone: {},
      ConsigneeSecondaryEmail: {}
    },
    Notify: {
      NotifyToName: {},
      NotifyToAddress: {},
      NotifyToPrimaryPhone: {},
      NotifyToSecondaryPhone: {},
      NotifyToPrimaryEmail: {},
      NotifyToSecondaryEmail: {}
    },
    Description: {
      DescriptionOfGoods: { required }
    },
    Documentation: {
      PortActivictyDocumentCargoId: { required },
      StowagePlanningBlDocumentDs: { required, minLength: minLength(2), maxLength: maxLength(50) }
    },
    DocumentList: { DocumentTypeActivity(value){
      if (value.length != 0) {
        if (Activity == process.env.VUE_APP_PORT_ACTIVITY_EXPORT_ID) {
          return !value.some(item => item.PortActivictyDocumentCargoId == process.env.VUE_APP_PORT_ACTIVICTY_DOCUMENT_CARGO_IMPORT_ID)
        }else{
          return !value.some(item => item.PortActivictyDocumentCargoId != process.env.VUE_APP_PORT_ACTIVICTY_DOCUMENT_CARGO_IMPORT_ID)
        }
      }else{
        return true;
      }
    }},
  }
}