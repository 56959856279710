import {
  required,
  minLength,
} from 'vuelidate/lib/validators';

const verifyNumber = (value) => {
  return value === 0 ? false : true;
}

const available = (param) => (value) => Number.parseFloat(value) < param;

export default (actualInventory, declared) => {
  return {
    estimatedInfo: {
      YardId: { required },
      StowagePlanningBlDetailId: {},
      BillOfLadingYardId: { required },
      UnitMeasureWeightBlDetId: { required },
      Weight: { required, verifyNumber, exceedtActualInventory (value) {
        if(actualInventory)
          return value <= Number.parseFloat(actualInventory.replace(".", "").replace(",", "."));
        else
          return value <= 0;
      }, notAvailableBl (value) {
        if(declared)
          return value <= Number.parseFloat(declared.replace(".", "").replace(",", "."));
        else
          return value <= 0;
      } },
      Status: {},
    },
  }
}