var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CTabs',{attrs:{"variant":"tabs","active-tab":0}},[_c('CTab',{staticClass:"form-especial",attrs:{"title":"DISTRIBUCION POR PATIO"}},[_c('CCard',{attrs:{"bodyWrapper":""}},[_c('CRow',[_c('CCol',{attrs:{"sm":"10"}},[_c('CRow',[_c('CCol',{staticClass:"mb-2",attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"size":"sm","placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":_vm.$t('label.yard'),"horizontal":{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                },"options":_vm.yards,"disabled":_vm.isEmpty(_vm.yards),"value":_vm.$v.estimatedInfo.YardId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.estimatedInfo.YardId),"is-valid":_vm.hasError(_vm.$v.estimatedInfo.YardId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.estimatedInfo.YardId, "$model", $event)}}})],1),_c('CCol',{staticClass:"mb-2",attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"size":"sm","placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":'BL',"horizontal":{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                },"options":_vm.blOptions,"disabled":_vm.isEmpty(_vm.bls),"value":_vm.$v.estimatedInfo.BillOfLadingYardId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.estimatedInfo.BillOfLadingYardId),"is-valid":_vm.hasError(_vm.$v.estimatedInfo.BillOfLadingYardId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.estimatedInfo.BillOfLadingYardId, "$model", $event)}}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"addLabelClasses":"text-right","horizontal":{
                  label: 'col-sm-12 col-lg-5',
                  input: 'col-sm-12 col-lg-7',
                },"label":_vm.$t('label.actualInventory'),"size":"sm","disabled":""},model:{value:(_vm.inventory),callback:function ($$v) {_vm.inventory=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inventory"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('div',{staticClass:"form-group form-row"},[_c('label',{staticClass:"required text-right col-sm-12 col-lg-5 col-form-label-sm"},[_vm._v(_vm._s(_vm.$t("label.weight") + " (TON)"))]),_c('div',{staticClass:"input-group col-sm-12 col-lg-7 input-group-sm"},[_c('money',_vm._b({staticClass:"form-control",attrs:{"addLabelClasses":"required","label":_vm.$t('label.weight'),"invalid-feedback":_vm.errorMessage(_vm.$v.estimatedInfo.Weight),"is-valid":_vm.hasError(_vm.$v.estimatedInfo.Weight),"value":"0"},model:{value:(_vm.$v.estimatedInfo.Weight.$model),callback:function ($$v) {_vm.$set(_vm.$v.estimatedInfo.Weight, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.estimatedInfo.Weight.$model"}},'money',_vm.measure,false)),(
                      !_vm.$v.estimatedInfo.Weight.verifyNumber &&
                      _vm.$v.estimatedInfo.BillOfLadingYardId.$anyDirty
                    )?_c('div',{staticClass:"invalid-feedback show-feedback"},[_vm._v(" "+_vm._s(_vm.$t("validation.verifyNumber"))+" ")]):_vm._e(),(
                      !_vm.$v.estimatedInfo.Weight.exceedtActualInventory &&
                      _vm.$v.estimatedInfo.BillOfLadingYardId.$anyDirty
                    )?_c('div',{staticClass:"invalid-feedback show-feedback"},[_vm._v(" "+_vm._s(_vm.$t("validation.exceedtActualInventory"))+" ")]):_vm._e(),(
                      !_vm.$v.estimatedInfo.Weight.notAvailableBl &&
                      _vm.$v.estimatedInfo.BillOfLadingYardId.$anyDirty
                    )?_c('div',{staticClass:"invalid-feedback show-feedback"},[_vm._v(" "+_vm._s(_vm.$t("validation.notAvailableBl"))+" ")]):_vm._e()],1)])]),(_vm.edit && _vm.modalEdit)?_c('CCol',{staticClass:"mt-1 mb-2",attrs:{"sm":"6"}},[_c('div',[_c('CSelect',{attrs:{"value":_vm.estimatedInfo.Status,"is-valid":_vm.statusSelectColor(),"horizontal":{
                    label: 'col-sm-12 col-lg-5',
                    input: 'col-sm-12 col-lg-7',
                  },"size":"sm","label":_vm.$t('label.status'),"options":_vm.statusOptions,"addLabelClasses":"text-right"},on:{"update:value":function($event){return _vm.$set(_vm.estimatedInfo, "Status", $event)}}})],1)]):_vm._e()],1)],1),_c('CCol',{staticClass:"d-flex justify-content-end align-items-start",attrs:{"sm":"2"}},[_c('CButtonGroup',[_c('CButton',{attrs:{"color":"add","size":"sm","shape":"square","disabled":_vm.isInvalid},on:{"click":_vm.confirmation}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.clearFields'),
                placement: 'top-end',
              }),expression:"{\n                content: $t('label.clearFields'),\n                placement: 'top-end',\n              }"}],staticClass:"ml-1",attrs:{"color":"wipe","size":"sm","shape":"square"},on:{"click":_vm.clearInputs}},[_c('CIcon',{attrs:{"name":"cil-brush-alt"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }