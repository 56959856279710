import { render, staticRenderFns } from "./manifiesto-modal.vue?vue&type=template&id=33f5c57d&scoped=true&"
import script from "./manifiesto-modal.vue?vue&type=script&lang=js&"
export * from "./manifiesto-modal.vue?vue&type=script&lang=js&"
import style0 from "./manifiesto-modal.vue?vue&type=style&index=0&id=33f5c57d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f5c57d",
  null
  
)

export default component.exports