import {
  required,
  helpers,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

import { textareaSpecials, onlyText } from '../funciones';

const alphaNumCustom = (value) => /^[\w\u00f1\u00d1\-]+$/g.test(value);
const TextareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);
const onlyNumbers = (value) => !helpers.req(value) || /^[0-9]+$/.test(value);
const textNotReq = (value) => !helpers.req(value) || onlyText(value);
const verifyNumber = (value) => {
  return value === "0,00" || value === "-0,00" ? false : true;
}
const temperature = (value) => !helpers.req(value) || /^[-]?[0-9]+$/g.test(value);
const equalPort = (param) => (value) => value != param;
const alphaNumCustomNotReq = (value) => !helpers.req(value) || alphaNumCustom(value);


export default (pol, pod, currentEstimated) => {
  return {
    stowageBillOfLoading: {
      StowagePlanningBillOfLadingId: {},
      StowagePlanningId: {},
      TpCargoId: { required },
      HeadingId: { required },
      CommodityId: { required },
      ClientTpId: { required },
      NroBl: { required, alphaNumCustom },
      Shipper: { textNotReq },
      NotifyParty: { textNotReq },
      LoadPortId: { required, notsameAsPol:equalPort(pod) },
      DischargePortId: { required, notsameAsPod:equalPort(pol) },
      ImdgId: {},
      PortActivityId: { required },
      BookingNumber: { alphaNumCustom: alphaNumCustomNotReq },
      UnitMeasureWeightBlId: {},
      Weight:{ required, verifyNumber, exceedEstimatedToShip (value) {
        return Number.parseFloat(`${value}`.replace(".", "").replace(",", ".")) < currentEstimated ? false : true;
      } },
      UnitMeasureVolumenBlId: {},
      Volumen:{ },
      UnitMeasureTemperatureId: {},
      Temperature:{ },
      Viscosity:{ },
      GeneralInfo: { TextareaNotReq },
      Condiction: { TextareaNotReq },
      StowagePlanningBlDetJson: {},
      Status: {},
    }
  }
}