<template><!-- resto -->
  <div>
    <loading-overlay :active="loadingGeneral" :is-full-page="true" loader="bars" />
      <CCard class="card-cliente">
        <CCardHeader class="bg-dark text-white">
          <CRow>
            <CCol sm="10" class="d-flex mt-1">{{collapseTitle}}</CCol>
            <CCol sm="2" v-if="!metalScrap">
              <CButton color="edit" size="sm" class="float-right" @click="tablaCollapse = !tablaCollapse" v-c-tooltip="{content: $t('label.dataTable')}">
                {{ cambiaTexto }}
                <CIcon :name="cambiaIcono"/>
              </CButton>
            </CCol>
            <CCol sm="2" class="d-flex justify-content-end" v-if="metalScrap">
              <button type="button" aria-label="Close" class="close" @click="cerrar">×</button>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
        <CRow>       
          <CCol sm="10">
            <CRow>
              <CCol sm="5" class="text-right mt-2 pr-1">
                <label><b class="text-danger">* </b>{{  $t('label.rifNumber') }}</label>
              </CCol>
              <CCol sm="2" class="px-1"><!-- rif -->
                <CSelect
                  size="sm"
                  v-uppercase
                  v-model="LetraRif"
                  :value.sync="LetraRif"
                  :options="LetraOptions"
                  :is-valid="hasError($v.LetraRif)"
                  :invalid-feedback="errorMessage($v.LetraRif)"
                />
              </CCol>
              <CCol sm="5"> <!-- rif -->
                <CInput
                  size="sm"
                  v-uppercase
                  v-model.trim="$v.ClientRif.$model"
                  ref="ClientRif"
                  maxlength="9"
                  minlength="9"
                  :is-valid="hasError($v.ClientRif)"
                  :invalid-feedback="errorMessage($v.ClientRif)"
                  @blur="$v.$touch()"
                  :placeholder="$t('label.rifNumber')"
                />
              </CCol>
              
              <CCol sm="12"><!-- nombre -->
                <CInput
                  size="sm"
                  :label="$t('label.name')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  :invalid-feedback="errorMessage($v.ClientName)"
                  maxlength="250"
                  v-model.trim="$v.ClientName.$model"
                  :is-valid="hasError($v.ClientName)"
                  :placeholder="$t('label.clientName')"                
                />
              </CCol>
              <CCol sm="12"><!-- representante -->
                <CInput
                  size="sm"
                  :label="$t('label.representative')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  :invalid-feedback="errorMessage($v.Representative)"
                  maxlength="50"
                  v-model.trim="$v.Representative.$model"
                  :is-valid="hasError($v.Representative)"
                  :placeholder="$t('label.clientRepresentativeName')"                
                />
              </CCol>
              <CCol sm="12"><!-- direccion -->
                <CTextarea
                  size="sm"
                  :label="$t('label.address')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  :invalid-feedback="errorMessage($v.ClientAddress)"
                  rows="2"
                  v-model.trim="$v.ClientAddress.$model"
                  :is-valid="hasError($v.ClientAddress)"
                  :placeholder="$t('label.clientAddress')"  
                  maxlength="500"              
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  size="sm"
                  :label="$t('label.mainPhone')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  ref="PrimaryPhone"
                  maxlength="15"
                  minlength="11"
                  v-model.trim="$v.PrimaryPhone.$model"
                  :is-valid="hasError($v.PrimaryPhone)"
                  :invalid-feedback="errorMessage($v.PrimaryPhone)"
                  :placeholder="$t('label.mainPhone')"                
                />
              </CCol>
              <CCol sm="12"> <!-- tlf  2 -->
                <CInput
                  size="sm"
                  :label="$t('label.secondaryPhone')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="text-right"
                  maxlength="15"
                  minlength="11"
                  v-model.trim="$v.SecondaryPhone.$model"
                  :is-valid="hasError($v.SecondaryPhone)"
                  :invalid-feedback="errorMessage($v.SecondaryPhone)"
                  :placeholder="$t('label.secondaryPhone')"                
                />
              </CCol>
              
              <CCol sm="12"><!-- email1 -->
                
                <CInput
                   size="sm"
                  :label="$t('label.mainEmail')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes="required text-right"
                  maxlength="100"
                  v-model.trim="$v.PrimaryEmail.$model"
                  :is-valid="hasError($v.PrimaryEmail)"
                  :invalid-feedback="errorMessage($v.PrimaryEmail)"
                  :placeholder="$t('label.mainEmail')"                
                />
              </CCol>
              <CCol sm="12"><!-- email2 -->
                <CInput
                  size="sm"
                  :label="$t('label.secondaryEmail')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  add-label-classes=" text-right"
                  maxlength="100"
                  :invalid-feedback="errorMessage($v.SecondaryEmail)"
                  v-model.trim="$v.SecondaryEmail.$model"
                  :is-valid="hasError($v.SecondaryEmail)"
                  :placeholder="$t('label.secondaryEmail')"                
                />
              </CCol>
              <CCol sm="12" lg="12">
                <CInput
                  type="color"
                  size="sm"
                  :label="$t('label.color')"
                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                  add-label-classes="text-right"
                  :is-valid="hasError($v.Color)"
                  v-model.trim="$v.Color.$model"
                />
              </CCol>
              <CCol sm="12" v-if="actualizar" ><!-- status -->
                <CSelect
                  size="sm"
                  :label="$t('label.status')"
                  :horizontal="{label: 'col-sm-5', input:'col-sm-7'}"
                  v-uppercase
                  addLabelClasses="text-right"
                  v-model="Status"
                  :value.sync="Status"
                  :is-valid="statusSelectColor"
                  :options="selectOptions"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="2">
            <CRow>
              <CCol sm="12">
                <CButton size="sm" color="add"  class="mr-1"  v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" @click="evaluaStatus" :disabled="isSubmit">
                  <CIcon name="checkAlt" />
                </CButton>
  
                <CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}" @click="limpiarDatos" :disabled="isSubmit">
                  <CIcon name="cil-brush-alt"/>
                </CButton>
  
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="mt-3 justify-content-center" v-if="!metalScrap">
          <CCol sm="11">
              <CCollapse :show="tablaCollapse" class="mt-2">
                <dataTableExtended
       class="align-center-row-datatable"
                  :items="itemsCliente"
                  :fields="fieldsCliente"
                  :loading="LoadingCliente"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination
                   
                >
                  <template #loading>
                    <loading/>
                  </template>
                  <template #Status="{item}">
                    <td class="center-cell">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Detalle="{item}">
                  <td class="center-cell">
                    <CButton color="edit" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.edit')+' '+$t('label.client')}" @click="editDatos(item)" >
                      <CIcon name="pencil" />
                    </CButton>
                    <!--<CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.delete')+' '+$t('label.client')}" @click="ConfirmarEliminado(item)" >
                      <CIcon name="cil-trash" />
                    </CButton>-->
                  </td>
                </template>
                </dataTableExtended>
              </CCollapse>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
  </div>
  </template>
  
  <script>
  import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
  import CustomTabs from '@/components/tabs/CustomTabs';
  import CustomTab from '@/components/tabs/CustomTab';
  import ClienteValidations from '@/_validations/cliente/clienteValidations';
  import UpperCase  from '@/_validations/uppercase-directive';
  import General from '@/_mixins/general';
  import { mapState } from 'vuex';
  import Loading from '../../components/loading.vue';
  
  //DATA
  function data () {
    return {
      //MODELO
      LetraRif: 'J',
      ClientRif: '',
      ClientName: '',
      ClientAddress: '',
      PrimaryPhone: '',
      SecondaryPhone: '',
      PrimaryEmail: '',
      SecondaryEmail: '',
      Representative: '',
      FgActClient: '1',
      Color: '',
      Status: 1,
      UserId: '',
      //VARIABLES
      itemsCliente: [],
    //  fieldsCliente,
      tablaCollapse: false,    
      LoadingCliente: false,    
      loadingGeneral: false,    
      LetraOptions: ['J','G','P','V'],
      tituloModal: '',
      actualizar: false,
      editCliente: false,
      iconoCollapse: 'cil-arrow-circle-top',
      statusOrigin: '',
      isSubmit: false, 
    }
  }
  
  //METHOD
  function limpiarDatos() {
    this.ClientRif = '';
    this.Representative = '';
    this.ClientAddress = '';
    this.ClientName = '';
    this.Color = '';
    this.actualizar = false;
    this.tituloModal= '',
    this.$nextTick(() => { this.$v.$reset() })
  }
  
  function limpiarContacto() {
    this.PrimaryEmail = '';
    this.SecondaryEmail = '';
    this.PrimaryPhone = '';
    this.SecondaryPhone = '';
    this.actualizar = false;
    this.$nextTick(() => { this.$v.$reset() })
  }
  
  function cerrar() {
      this.limpiarDatos();
      this.limpiarContacto();
      this.$emit('child-refresh',true)
  }
  
  function evaluaStatus() {
    if(this.metalScrap) {
      this.guardar();
    } else {
      if (this.Status!==this.statusOrigin && this.actualizar) {
        this.$swal.fire(
          alertPropertiesHelpers(this,{
             text: `${this.$t('label.changeStatusQuestion')}?`,
          })
          ).then((result) => {
          if (result.isConfirmed) {
            this.guardar();
          }
        });  
      } else this.guardar();
    }
  }
  
  function guardar () {
   // if (this.$v.$invalid) return false;
   try {
      this.loadingGeneral = true;
      this.isSubmit = true;
      this.$v.$touch();
  
      if (this.$v.$error) {
        this.loadingGeneral = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }
    
    let res = [];
    let rif = this.LetraRif+this.ClientRif;
    let metodo = '';
    let ruta = '';
    let ClientJson = [];
    
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.actualizar) {
      ClientJson = {
        ClientId: this.ClientId,
        ClientRif: rif,
        ClientName: this.ClientName,
        ClientAddress: this.ClientAddress,
        PrimaryEmail: this.PrimaryEmail,
        SecondaryEmail: this.SecondaryEmail,
        PrimaryPhone: this.PrimaryPhone,
        SecondaryPhone: this.SecondaryPhone,
        Representative: this.Representative,
        Status: this.Status,
        UserId: this.user.UserId,
        Color: this.Color,
      };
      metodo = 'PUT';
      ruta = "Client-update";
    } else {
      if(this.tpClientId) {
        ClientJson = {
          ClientRif: rif,
          ClientName: this.ClientName,
          TpClientId: this.tpClientId,
          ClientAddress: this.ClientAddress,
          PrimaryEmail: this.PrimaryEmail,
          SecondaryEmail: this.SecondaryEmail,
          PrimaryPhone: this.PrimaryPhone,
          SecondaryPhone: this.SecondaryPhone,
          Representative: this.Representative,
          Color: this.Color,
        };
      }else{
        ClientJson = {
          ClientRif: rif,
          ClientName: this.ClientName,
          ClientAddress: this.ClientAddress,
          PrimaryEmail: this.PrimaryEmail,
          SecondaryEmail: this.SecondaryEmail,
          PrimaryPhone: this.PrimaryPhone,
          SecondaryPhone: this.SecondaryPhone,
          Representative: this.Representative,
          Color: this.Color,
        };
      }
      metodo = 'POST';
      ruta = !this.metalScrap ? "ClientCompServices-insert" : this.metalScrap;
    }
  
    this.$http.ejecutar(metodo, ruta, ClientJson, { root: 'ClientJson' })
    .then(response => {
      res = [...response.data.data];
      this.cerrar()
      if(!this.metalScrap)
        this.listarClientes();
      else
        this.$emit('update-list');
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: res[0].Response,
        type: "success"
      });
      this.loadingGeneral= false;
      //this.$emit('', truchild-refreshe);
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingGeneral= false;
    }).then(() => {
       this.isSubmit = false;
      });
    } catch (e) {
      this.isSubmit = false;
      this.loadingGeneral= false;
      //this.notifyError({ text: e });
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  }
  
  function listarClientes () {
    this.itemsCliente = [];
    this.LoadingCliente = true;
    
    let listado = [];
    this.$http.get("Client-list", { filter: 'ALL' })
    .then(response => {
      let arreglo = [...response.data.data];
      let arreglo2 = [];
      
      for (let i=0; i<arreglo.length; i++){
        arreglo2 = arreglo[i].ClientTpJson;
        if (arreglo2!=null) {
          for (let y=0; y<arreglo2.length; y++){
            if (arreglo2[y].TpClientId==='ED88125A-CD80-4C72-9A5F-90BAED4600C7'){
              listado.push(arreglo[i]);
            }        
          } 
        }      
      }
      
      this.itemsCliente = listado.map(listado => Object.assign({}, listado, {
        Nro: listado.Nro,
        Cliente: listado.ClientName,
        Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
        Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
        Status: listado.Status,
        ClientId: listado.ClientId,
        ClientRif: listado.ClientRif,
        ClientAddress: listado.ClientAddress,
        PrimaryEmail: listado.PrimaryEmail,
        SecondaryEmail: listado.SecondaryEmail,
        PrimaryPhone: listado.PrimaryPhone,
        SecondaryPhone: listado.SecondaryPhone,
        Representative: listado.Representative,
        FgActClient: listado.FgActClient,
        _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
      }));
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.LoadingCliente = false;
    });
  }
  
  function editDatos(item) {
    let rif             = item.ClientRif;
    this.actualizar     = true;
    this.ClientName     = item.Cliente;
    this.ClientId       = item.ClientId;
    this.ClientAddress  = item.ClientAddress;
    this.PrimaryEmail   = item.PrimaryEmail;
    this.SecondaryEmail = item.SecondaryEmail;
    this.PrimaryPhone   = item.PrimaryPhone;
    this.SecondaryPhone = item.SecondaryPhone;
    this.Representative = item.Representative;
    this.Status         = item.FgActClient ? 1 : 0;
    this.ClientRif      = rif.substr(1, (rif.length - 1));
    this.LetraRif       = rif.substr(0,1);
    this.Color          = item.Color;
    this.selectOptions[this.Status];
    this.tituloModal=': '+this.LetraRif+'-'+this.ClientRif+' - '+this.ClientName;
    this.statusOrigin= item.FgActClient ? 1 : 0;
    this.$v.$touch();
  }
  
  /*function ConfirmarEliminado(item) {
    this.$swal.fire(
      alertPropertiesHelpers(this,{
           text: `${this.$t('label.changeStatusQuestion')} ${item.ClientName}?`,
      })
      ).then((result) => {
      if (result.isConfirmed) {
        this.loadingGeneral = true;
        let res = [];
        let ClientJson = [];
  
        ClientJson = {
          ClientId: item.ClientId,
          ClientRif: item.ClientRif,
          ClientName: item.ClientName,
          ClientAddress: item.ClientAddress,
          PrimaryEmail: item.PrimaryEmail,
          SecondaryEmail: item.SecondaryEmail,
          PrimaryPhone: item.PrimaryPhone,
          SecondaryPhone: item.SecondaryPhone,
          Representative: item.Representative,
          Status: 0,
          UserId: this.user.UserId,
        };
  
        this.$http.put("Client-update", ClientJson, { root: 'ClientJson' })
        .then(response => {
          res = [...response.data.data];
          this.$notify({
            group: 'container',
            title: '¡Exito!',
            text: res[0].Response,
            type: "success"
          });
          this.limpiarDatos();
          this.limpiarContacto();
  
          if(!this.metalScrap)
            this.listarClientes();
  
          this.loadingGeneral= false;    
        }).catch((err) => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
          this.loadingGeneral= false;
        });
      }
    });  
  }*/
  
  //COMPUTED
  function isDisabled() {
    return this.$v.$invalid
  }
  
  function selectOptions(){
    return [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ];
  }
  
  function fieldsCliente() {
    return [
      { 
        key: 'Nro', 
        label: '#',
        filter: false,
        _style: 'width:1%;'
      },
      { key: 'Cliente', label:this.$t('label.client'), _classes: 'text-uppercase text-center center-cell' },
      { key: 'ClientRif', label:this.$t('label.rifNumber'), _classes: 'text-uppercase text-center center-cell', _style: 'width:12%;' },
      { key: 'Usuario', label:this.$t('label.user'), _classes: 'text-uppercase text-center center-cell', _style: 'width:12%;' },
      { key: 'Fecha', label:this.$t('label.date'), _classes: 'text-center center-cell', _style: 'width:12%;' },
      { key: 'Status', label:this.$t('label.status'), _classes: 'text-center center-cell', _style: 'width:12%;' },
      { 
        key: 'Detalle',
        label: '',
        sorter: false,
        filter: false,
        _classes: 'text-center center-cell',
        _style: 'min-width:45px; width:9%;'
      }
    ];
  }
  
  function cambiaIcono() {
    return this.iconoCollapse = (this.tablaCollapse) ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
  }
  function cambiaTexto() {
    return this.textoCollapse = (this.tablaCollapse) ? this.$t("label.close") : this.$t("label.toView");
  }
  function collapseTitle(){
    if(this.title) return this.title;
    if(!this.metalScrap) {
      return this.actualizar ?  `${this.$t('label.edit')} ${this.$t('label.supplier')}: ${this.ClientName}` : `${this.$t('label.nuevo')} ${this.$t('label.supplier')}`;
    }else {
      switch (this.metalScrap) {
        case 'ClientCarrier-insert':
          return `${this.$t('label.nuevo')} ${this.$t('label.carrierCompany')}`;
        case 'ClientConsigne-insert':
          return `${this.$t('label.nuevo')} ${this.$t('label.client')}`;
        default:
          return `${this.$t('label.nuevo')}`;
      }
    }
  }
  
  export default {
    name: 'add-cliente',
    mixins: [General],
    components:{ 
      CustomTabs,
      CustomTab,
      Loading
    },
    data,
    props: {
      value: [Boolean, Object, String],
      metalScrap: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      tpClientId: {
        type: String,
        default: '',
      }
    },
    directives: UpperCase,
    validations: ClienteValidations,
    watch: {
      
    },
    methods: {
      evaluaStatus,
      limpiarDatos,
      limpiarContacto,
      guardar,
      cerrar,
      editDatos,
      listarClientes,
      //ConfirmarEliminado
    },
    computed: {
      isDisabled,
      cambiaIcono,
      cambiaTexto,
      selectOptions,
      fieldsCliente,
      collapseTitle,
      ...mapState({
          user: state => state.auth.user,
      })
    },
    mounted () {
      if (this.value) {
          //this.setLineaData(this.value);
      }
      if(!this.metalScrap)
        this.listarClientes();
    },
  }
  </script>
  <style lang="scss">
  .center-cell {
    text-align: center;
  }
   
  .card-client{
    padding-left: 1.7rem;
    padding-right: 1.7rem;
  }
  </style>