<template>
  <div class="mt-3">
		<loading-overlay :active="isLoading" :is-full-page="true" loader="bars" />
    <ManifiestoCargaModal
			:modal.sync="modal"
      :billOfLoadingId="billOfLoadingId"
      :edit="edit"
      @submited="handleSubmit"
    />
		<GeneralCargoModal
      :modal.sync="ActivateGeneralCargoModal"
      :GeneralCargoData="GeneralCargoData"
      :isEdit="edit"
			@Close="(ActivateGeneralCargoModal=false, edit=false, GeneralCargoData={})"
      @submited="handleSubmit"
    />
    <CCollapse :show="!ShowBlContainerList">
      <CCol sm="12">
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end">
            <CButton
              shape="square"
              color="add"
              v-c-tooltip="{ placement: 'top-end', content: 'BL' }"
              class="d-flex align-items-center"
              @click="toggleModal(false, null)"
            >
              <CIcon name="cil-playlist-add" /><span class="ml-1">{{
                $t("label.nuevo")
              }}</span>
            </CButton>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              sorter
              column-filter
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items="formatedItems"
              :fields="fields"
              :noItemsView="tableText.noItemsViewText"
              :items-per-page="5"
              :active-page="activePage"
              pagination
            >
              <template #Status="{ item }">
                <td class="text-center">
                  <div>
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t("label." + item.Status) }}
                    </CBadge>
                  </div>
                </td>
              </template>

              <template #Details="{ item, index }">
                <td class="text-center">
                  <CButton
                    color="edit"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                      content: $t('label.edit') + 'BL',
                      placement: FgGeneralCargo ? 'top-start' : 'top-end',
                    }"
                    @click="toggleModal(true, item)"
                  >
                    <CIcon name="pencil" />
                  </CButton>
                  <CButton
                    v-if="FgGeneralCargo && item.CargoJson && item.CargoJson.length != 0"
                    color="watch"
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.ContainerList'),
                      placement: FgGeneralCargo ? 'top-start' : 'top-end',
                    }"
                    @click="(ShowBlContainerList=true, itemBl=item, Index=index)"
                  >
                    <CIcon name="containerSizeIcon" />
                  </CButton>
                </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
      </CCol>
    </CCollapse>
    <CCollapse :show="ShowBlContainerList">
      <BlContainerList
        :active="ShowBlContainerList"
        :Bl="itemBl"
        :Index="Index"
        :BlList="items"
        @Close="(ShowBlContainerList=false, itemBl={}, Index=0)"
        @Update="Update"
      />
    </CCollapse>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import ManifiestoCargaModal from "./manifiesto-modal";
import BlContainerList from "./bl-container-list"
import GeneralCargoModal from "./general-cargo-modal/general-cargo-modal-index";

function fields() {
  if (this.FgGeneralCargo) {
    return [
      {
        key: "Details",
        label: "",
        sorter: false,
        filter: false,
        _style: "min-width:90px; width:1%;",
      },
      { key: "Nro", label: "#", _classes: "text-center", filter: false,},
      { key: "BL", label: 'BL',  _classes: 'text-center',  _style: "min-width: 130px;"},
      { key: "ClientName", label: this.$t("label.client"), _classes: 'text-center', _style: "min-width: 130px"},
      { key: "Activity", label: this.$t("label.activity"), _classes: 'text-center', _style: "min-width: 130px"},
      { key: "Commodity", label: this.$t("label.commodity"), _classes: 'text-center', _style: "min-width: 130px"},
      { key: "ComputedWeight", label: this.$t("label.weight")+' (KGM)',  _classes: 'text-center', _style: "min-width: 130px"},
      { key: "VolumenM3", label: this.$t("label.volume")+' (M³)',  _classes: 'text-center', _style: "min-width: 135px"},
      { key: "ComputedQuantity", label: this.$t("label.quantity"),  _classes: 'text-center', _style: "min-width: 130px"},
      { key: "Usuario", label: this.$t("label.user"), _classes: 'text-center', _style: "min-width: 130px"},
      { key: "Fecha", label: this.$t("label.date"), _classes: "text-center", _style: "min-width: 130px"},
      { key: "Status", label: this.$t("label.status"), _classes: "text-center", _style: "min-width: 130px"},
    ];
  }else{
    return [
      { key: "Nro", label: "#", _style: "width:3%;", _classes: "text-center", filter: false,},
      { key: "BL", label: 'BL',  _classes: 'text-center', _style: "width:10%;"},
      { key: "ClientName", label: this.$t("label.client"), _classes: 'text-center', _style: "width:10%;"},
      { key: "Activity", label: this.$t("label.activity"), _classes: 'text-center', _style: "width:10%;"},
      { key: "Cargo", label: this.$t("label.commodity"), _classes: 'text-center', _style: "width:15%;"},
      { key: "ComputedWeight", label: this.$t("label.quantity")+' (TON)',  _classes: 'text-center', _style: "width:15%;"},
      { key: "Usuario", label: this.$t("label.user"), _classes: 'text-center', _style: "width:10%;"},
      { key: "Fecha", label: this.$t("label.date"), _classes: "text-center", _style: "width:10%;"},
      { key: "Status", label: this.$t("label.status"), _classes: "text-center", _style: "width:10%;"},
      {
        key: "Details",
        label: "",
        sorter: false,
        filter: false,
        _style: "min-width:45px; width:1%;",
      },
    ];
  }
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    BL: "align-middle",
    ClientName: "align-middle",
    Activity: "align-middle",
    Cargo: "align-middle",
    ComputedWeight: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//data
function data() {
  return {
    Index: 0,
    isLoading: false,
    ShowBlContainerList: false,
    ActivateGeneralCargoModal: false,
    items: [],
    itemBl: {},
    GeneralCargoData: {},
    // loading: false,
    modal: false,
    edit: false,
    billOfLoadingId: '',
    activePage: 1,
  };
}

//methods
function getBillList() {
  this.isLoading = true;
  this.$http
    .get("StowagePlanningBillOfLading-list", { StowagePlanningId: this.planificacionId })
    .then((response) => {
      this.items = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoading = false;
    });
}
function toggleModal(edit = false, item = null) {
  if (this.FgGeneralCargo) {
    this.edit = edit ? true : false;
    this.GeneralCargoData = item;
    this.ActivateGeneralCargoModal = true;
  } else {
    this.edit = edit ? true : false;
    this.billOfLoadingId = item ? item.StowagePlanningBillOfLadingId : '';
    this.modal = true;
  }
}
async function handleSubmit(){
  await this.getBillList();
}

function Update(Bl, List) {
  this.itemBl = Bl ?? {};
  this.items = List ? [...List] : [];
}

function Reset() {
  this.modal = false;
  this.edit = false;
  this.ShowBlContainerList = false;
  this.billOfLoadingId = '';
  this.itemBl = {};
  this.Index = 0;
  this.GeneralCargoData = {};
}

//computed
function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1);
  return this.items.map((bill) =>{
    return {
      ...bill,
      Nro: bill.Nro,
      BL: bill.NroBl,
      ClientName: bill.ClientName,
      Activity: bill['PortActivityName'+this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1)],
      Cargo: bill.CommodityName,
      Commodity: bill['CommodityName'+_lang] ?? '',
      ComputedWeight: NumberFormater.formatNumber(bill.Weight, 2),
      VolumenM3: NumberFormater.formatNumber(bill.Volumen, 2),
      ComputedQuantity: NumberFormater.formatNumber(bill.Quantity, 2),
      Usuario: bill.TransaLogin ? bill.TransaLogin : "N/A",
      Fecha: bill.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(bill.TransaRegDate)
        : "N/A",
      Status: bill.Status,
      _classes: bill.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableClasses,
    }
  });
}

export default {
  name: "bl-index",
  mixins: [General],
  data,
	props: {
		active: {
			type: Boolean,
			default: false,
		}
	},
  components: {
    ManifiestoCargaModal,
		GeneralCargoModal,
    BlContainerList,
  },
  methods: {
    toggleModal,
    getBillList,
    handleSubmit,
    Update,
    Reset,
  },
  computed: {
    fields,
    cellTableClasses,
    formatedItems,
    ...mapState({
      planificacionId: state => state.planificacionestiba.planificacionId,
      bulkCarrier: state => state.planificacionestiba.bulkCarrier,
      FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
    }),
  },
  watch: {
    modal: function (val) {
      if (!val) {
        this.edit = false;
        this.billOfLoading = '';
      }
    },
    active: function (Newval) {
      if (Newval) {
        this.getBillList();
      }else{
        this.Reset();
			}
    },
  },
};
</script>

<style scoped>
.center-cell {
  text-align: center;
}
</style>