<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12" lg="5">
      <CSelect
        size="sm"
        :placeholder="$t('label.select')"
        addLabelClasses="required text-right"
        :label="getClientLabel"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        :options="ClientOptions"
        :disabled="!Activity"
        v-model="$v.Consignee.ClientTp.$model"
        :invalid-feedback="errorMessage($v.Consignee.ClientTp)"
        :is-valid="hasError($v.Consignee.ClientTp)"
        @change="SelectConsignee($event.target.value)"
      >
        <template #append>
          <CButton
            color="add"
            size="sm"
            :disabled="!Activity"
            v-c-tooltip="{
              content: $t('label.nuevo') + getClientLabel,
              placement: 'top-end',
            }"
            style="padding: 0.15rem 0.4rem; border-radius: 0.2rem;"
            @click="getTpClientId"
          >
            <CIcon name="cil-playlist-add" />
          </CButton>
        </template>
      </CSelect>
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        disabled
        size="sm"
        v-uppercase
        addLabelClasses="text-right"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        :label="getRifLabel"
        v-model.trim="$v.Consignee.RifClient.$model"
        :invalid-feedback="errorMessage($v.Consignee.RifClient)"
        :is-valid="hasError($v.Consignee.RifClient)"
      />
    </CCol>
    <CCol sm="12">
      <CCollapse :show="AddClient">
        <AddCliente
          metalScrap="ClientPortActivity-insert"
          :tpClientId="TpClientId"
          :title="getCollapseTitle"
          @child-refresh="(AddClient=false, TpClientId='')"
          @update-list="getService"
        />
      </CCollapse>
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.name')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        v-model="$v.Consignee.ConsigneeName.$model"
        :is-valid="hasError($v.Consignee.ConsigneeName)"
        :invalid-feedback="errorMessage($v.Consignee.ConsigneeName)"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.address')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        v-model="$v.Consignee.ConsigneeAddress.$model"
        :is-valid="hasError($v.Consignee.ConsigneeAddress)"
        :invalid-feedback="errorMessage($v.Consignee.ConsigneeAddress)"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.mainPhone')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        v-model="$v.Consignee.ConsigneePrimaryPhone.$model"
        :is-valid="hasError($v.Consignee.ConsigneePrimaryPhone)"
        :invalid-feedback="errorMessage($v.Consignee.ConsigneePrimaryPhone)"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.secondaryPhone')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        v-model="$v.Consignee.ConsigneeSecondaryPhone.$model"
        :is-valid="hasError($v.Consignee.ConsigneeSecondaryPhone)"
        :invalid-feedback="errorMessage($v.Consignee.ConsigneeSecondaryPhone)"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.mainEmail')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        v-model="$v.Consignee.ConsigneePrimaryEmail.$model"
        :is-valid="hasError($v.Consignee.ConsigneePrimaryEmail)"
        :invalid-feedback="errorMessage($v.Consignee.ConsigneePrimaryEmail)"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.secondaryEmail')"
        :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
        v-model="$v.Consignee.ConsigneeSecondaryEmail.$model"
        :is-valid="hasError($v.Consignee.ConsigneeSecondaryEmail)"
        :invalid-feedback="errorMessage($v.Consignee.ConsigneeSecondaryEmail)"
      />
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';
import AddCliente from "@/pages/cliente/add-cliente";

function data() {
  return {
    AddClient: false,
    ClientList: [],
    TpClientId: '',
  };
}

//methods
function getService() {
  this.$emit('Loading', true);
  let requests = [
    this.$http.ejecutar("GET", "Client-list-by-PortActivityId", { PortActivityId: this.Activity, }),
  ];
    Promise.all(requests)
     .then((responses) => {
      this.ClientList = responses[0].data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$emit('Loading', false);
    });
}

function getTpClientId() {
  if (!this.AddClient) {
    this.$emit('Loading', true);
    this.$http.ejecutar("GET", "TpClientPortActivity-list", { PortActivityId: this.Activity })
      .then((responses) => {
        this.TpClientId = responses?.data?.data&&responses.data.data[0] ? responses.data.data[0].TpClientId : '';
        this.AddClient = this.TpClientId ? true : false;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .finally(() => {
        this.$emit('Loading', false);
      });
  } else {
    this.AddClient = false;
  }
}

function SelectConsignee(Value) {
  this.Consignee.ClientTp = Value;
  this.$emit('UpdateConsignee');
  if (this.Consignee.ClientTp) {
    let data = this.ClientList.find((item) => item.ClientTpId == this.Consignee.ClientTp);
   
    //llenado de campos según el agente aduanal
    this.Consignee.RifClient = data?.ClientRif ?? '';
    this.Consignee.ConsigneeAddress = data?.ClientAddress ?? '';
    this.Consignee.ConsigneeName = data?.ClientName ?? '';
    this.Consignee.ConsigneePrimaryPhone = data?.PrimaryPhone ?? '';
    this.Consignee.ConsigneeSecondaryPhone = data?.SecondaryPhone ?? '';
    this.Consignee.ConsigneePrimaryEmail = data?.PrimaryEmail ?? '';
    this.Consignee.ConsigneeSecondaryEmail = data?.SecondaryEmail ?? '';

    this.$v.Consignee.$touch();
  }else{
    
    //reseteo de los campos
    this.Consignee.RifClient = '';
    this.Consignee.ConsigneeAddress =  '';
    this.Consignee.ConsigneeName =  '';
    this.Consignee.ConsigneePrimaryPhone = '';
    this.Consignee.ConsigneeSecondaryPhone =  '';
    this.Consignee.ConsigneePrimaryEmail =  '';
    this.Consignee.ConsigneeSecondaryEmail =  '';
  }
}

function Reset() {
  this.AddClient = false;
  this.ClientList = [];
  this.TpClientId = '';
}

//computed
function ClientOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ClientList.map(function(e){
    chart.push({
      label: e.ClientName,
      value: e.ClientTpId,
    })    
  })
  return chart;
}

function isExporter() {
  return typeof this.Activity == 'string' && this.Activity.toUpperCase() == process.env.VUE_APP_PORT_ACTIVITY_EXPORT_ID;
}

function getClientLabel() {
  if(this.isExporter)
    return this.$t('label.exporter');
  else
    return this.$t('label.importer');
}

function getRifLabel() {
  if(this.isExporter)
    return this.$t('label.rifExporter');
  else
    return this.$t('label.rifImporter');
}

function getCollapseTitle() {
  if(this.isExporter)
    return this.$t('label.clientExporter');
  else
    return this.$t('label.clientImporter');
}

export default {
  name: 'consignee-tab',
  components: {
    AddCliente,
  },
  data,
  props: {
    Consignee: {
      type: Object,
      required: true,
      default: () => {},
    },
    Activity: {
      required: true,
      default: () => '',
    },
    isEdit: Boolean,
    showModal: Boolean,
    Tab: Boolean,
    Validate: Boolean,
  },
  mixins: [ModalMixin],
  directives: UpperCase,
  validations: GeneralCargoBlValidations,
  methods: {
    getService,
    getTpClientId,
    SelectConsignee,
    Reset,
  },
  computed:{
    ClientOptions,
    isExporter,
    getClientLabel,
    getRifLabel,
    getCollapseTitle,
  },
  watch: {
    showModal: function (Newval) {
      if (Newval&&this.isEdit) {
        this.$v.Consignee.$touch();
      }else{
        this.Reset();
        this.$v.$reset();
      }
    },
    Tab: function (Newval) {
      if (Newval&&this.Activity) {
        this.getService();
      }
    },
    Activity: function (NewVal, OlVal){
      if (OlVal != '') {
        this.SelectConsignee('');
      }
    },
    Validate: function (NewVal){
      if (NewVal) {
        this.$v.Consignee.$touch();
      }
    }
  },
};
</script>
<style>

</style>