<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12" lg="12">
      <CCol sm="12" class="mb-2">
        <vue-editor
          :placeholder="$t('label.description')"
          v-model="$v.Description.DescriptionOfGoods.$model"
          :class="!$v.Description.DescriptionOfGoods.$dirty ? '' : 
            ($v.Description.DescriptionOfGoods.$error ? 'select-description--error' : 'select-description--correct')"
          @input="$emit('UpdateDescription')"
        >
        </vue-editor>
        <div v-if="$v.Description.DescriptionOfGoods.$error" style="display: block;" class="invalid-feedback">
          {{ errorMessage($v.Description.DescriptionOfGoods) }}
        </div>
      </CCol>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import { VueEditor } from "vue2-editor";
  
function data() {
  return {
  };
}

//computed
  
export default {
  name: 'description-tab',
  components: {
      VueEditor
  },
  data,
  props: {
  Description: {
      type: Object,
      required: true,
      default: () => {},
  },
    isEdit: Boolean,
    showModal: Boolean,
    Validate: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  validations() {
    return GeneralCargoBlValidations();
  },
  methods: {

  },
  computed:{

  },
  watch: {
    showModal: function (Newval) {
      if (Newval&&this.isEdit) {
        this.$v.Description.$touch();
      }else{
        this.$v.$reset();
      }
    },
    Validate: function (NewVal){
      if (NewVal) {
        this.$v.Description.$touch();
      }
    }
  }
};
</script>